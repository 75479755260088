// import React, { useState,useEffect } from "react";
// import Modal from "common/components/Modal";
// import Select from "common/components/Select";
// import axios from "common/configs/axiosConfigs";


// const Filter = ({ onClose, isOpen }) => {
//   const [filteredProject , setFilteredPRoject] = useState([""])
//   const [propertyType , setPropertyType] = useState([""])
//   const getFilteredprojects = async () => {
//     try {
//       const { data } = await axios.post("", {
//         action: "getWebProjects",
//         propertyType: "",
//         location: "",
//         furnished: "",
//         bedroom: "",
//         minBudget: "",
//         maxBudget: ""
//       });

//       if (data?.success) {
//         console.log("dataaaaaaa",data)
//         setFilteredPRoject(data?.data);
//       } else {
//         console.error(data?.error);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const getPropertyType = async () => {
//     try {
//       const { data } = await axios.post("", {
//         action: "getPropertyType",
//       });

//       if (data?.success) {
//         console.log("dataaaaaaa",data)
//         setPropertyType(data?.data);
//       } else {
//         console.error(data?.error);
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };
//   return (
//     <Modal
//       {...{
//         onClose,
//         isOpen,
//         title: "Property Filters",
//         footer: (
//           <>
//             <button type="button" className="btn btn-light">
//               Cancel
//             </button>
//             <button type="button" className="btn btn-primary">
//               Apply
//             </button>
//           </>
//         ),
//       }}
//     >
//       <div className="filter-box row gy-4 ">
//         {/* <div className="col-lg-6">
//           <Select placeholder="Status" options={[{ value: "sale", label: "For Sale" }, { value: "rent", label: "For Rent" }]} />
//         </div> */}
//         <div className="col-lg-6">
//           <Select placeholder="Type" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//         <div className="col-lg-6">
//           <Select placeholder="Location" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//         <div className="col-lg-6">
//           <Select placeholder="BHK" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//         <div className="col-lg-6">
//           <Select placeholder="Furnishing" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//         <div className="col-lg-3 col-6">
//           <Select placeholder="Min Budget" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//         <div className="col-lg-3 col-6">
//           <Select placeholder="Max Budget" options={[{ value: "sale", label: "For Sale" }]} />
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default Filter;

import React, { useState,useEffect } from "react";
import Modal from "common/components/Modal";
import Select from "common/components/Select";
import axios from "common/configs/axiosConfigs";


const Filter = ({ onClose, isOpen, onApply,projectFilter }) => {
  const [propertyType, setPropertyType] = useState([]);
  const [location, setLocation] = useState(null);
  const [furnished, setFurnished] = useState(null);
  const [bedroom, setBedroom] = useState(null);
  const [minBudget, setMinBudget] = useState(null);
  const [maxBudget, setMaxBudget] = useState(null);
  const [filterData, setFilterData] = useState({});


  const handleApply = () => {
    onApply({
      propertyType,
      location,
      furnished,
      bedroom,
      minBudget,
      maxBudget,
    });
    projectFilter(filterData);
    onClose(); // Close the modal after applying filters
  };

  const getPropertyType = async () => {
        try {
          const { data } = await axios.post("", {
            action: "getWebPropertyType",
          });
    
          if (data?.success) {
            console.log("property-type",data)
            setPropertyType(data?.data);
          } else {
            console.error(data?.error);
          }
        } catch (err) {
          console.error(err);
        }
      };
  
    useEffect(() => {
      getPropertyType();
    }, [])
    
    const handleSelect = ({target}) => {
      setFilterData((prev)=>({...prev,[target.name]:target.value}))
    }

  return (
    <Modal
      {...{
        onClose,
        isOpen,
        title: "Property Filters",
        footer: (
          <>
            <button type="button" className="btn btn-light" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={handleApply}>
              Apply
            </button>
          </>
        ),
      }}
    >
      <div className="filter-box row gy-4 ">
        <div className="col-lg-6">
          <Select
            name="propertyType"
            placeholder="Type"
            options={propertyType.map((p)=>({value: p.id,label:p.name}))}
            onChange={handleSelect}
          />
        </div>
        <div className="col-lg-6">
          <input
            name="location"
            type="text"
            placeholder="Location"
            className="form-control"
            onChange={handleSelect}
          />
        </div>
        <div className="col-lg-6">
          <Select
            name="bedroom"
            placeholder="BHK"
            options={[{ value: "1", label: "1 BHK" }, { value: "2", label: "2 BHK" }, { value: "3", label: "3 BHK" }, { value: "4", label: "4 BHK" }, { value: "5", label: "5 BHK" }, { value: "6", label: "6 BHK" }, { value: "7", label: "7 BHK" }, { value: "8", label: "8 BHK" }, { value: "9", label: "9 BHK" }, { value: "10", label: "10 BHK" }, { value: "11", label: "11 BHK" }, { value: "12", label: "12 BHK" }, { value: "13", label: "13 BHK" }, { value: "14", label: "14 BHK" }, { value: "15", label: "15 BHK" }]}
            onChange={handleSelect}
          />
        </div>
        <div className="col-lg-6">
          <Select
            name="furnished"
            placeholder="Furnishing"
            options={[{ value: "furnished", label: "Furnished" }, { value: "unfurnished", label: "Unfurnished" },{ value: "semifurnished", label: "Semi-Furnished" }]}
            onChange={handleSelect}
          />
        </div>
        <div className="col-lg-6 col-6">
          <input
          name="minBudget"
          type="number"
          min={0}
            className="form-control"
            placeholder="Min Budget"
            options={[{ value: "1000", label: "1000" }, { value: "2000", label: "2000" }]}
            onChange={handleSelect}
          />
        </div>
        <div className="col-lg-6 col-6">
          <input 
          name="maxBudget"
          type="number"
          min={0}
            className="form-control"
            placeholder="Max Budget"
            options={[{ value: "5000", label: "5000" }, { value: "10000", label: "10000" }]}
            onChange={handleSelect}
          />
        </div>
      </div>
    </Modal>
  );
};

export default Filter;
