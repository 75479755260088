// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
// import { FreeMode, Navigation, Thumbs } from "swiper/modules";
// import projects1 from "assets/img/projects-1.jpeg";
// import { LuBedDouble } from "react-icons/lu";
// import { BiBath } from "react-icons/bi";
// import { TbLayersIntersect } from "react-icons/tb";

// const projectsDetails = () => {
//   const [thumbsSwiper, setThumbsSwiper] = useState(null);
//   const { id } = useParams();
//   return (
//     <>
//       <section>
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-5">
//               <Swiper
//                 style={{
//                   "--swiper-navigation-color": "#fff",
//                   "--swiper-pagination-color": "#fff",
//                 }}
//                 spaceBetween={10}
//                 navigation={true}
//                 thumbs={{ swiper: thumbsSwiper }}
//                 modules={[FreeMode, Navigation, Thumbs]}
//                 className="main-swiper"
//               >
//                 {Array.from({ length: 8 }).map((_, i) => (
//                   <SwiperSlide>
//                     <div className="theme-img">
//                       <img src={projects1} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//               <Swiper
//                 onSwiper={setThumbsSwiper}
//                 freeMode={true}
//                 watchSlidesProgress={true}
//                 modules={[FreeMode, Navigation, Thumbs]}
//                 className="sub-swiper"
//               >
//                 {Array.from({ length: 8 }).map((_, i) => (
//                   <SwiperSlide>
//                     <div className="theme-img">
//                       <img src={projects1} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </div>
//             <div className="col-lg-7">
//               <h4>960 Sq.Ft. 3 BHK Residential Apartment for Sale in Mulund (West)</h4>
//               <p className="text-muted">Mulund (West) , Mumbai Central Suburbs</p>
//               <div className="d-flex mb-4 align-items-center">
//                 <h3 className="text-primary mb-0">$1,50,000</h3>
//                 <p className="mb-0 ms-2">EMI: $5000/month</p>
//               </div>
//               <div className="d-flex  projects-features projects-features-v2">
//                 <span>
//                   <LuBedDouble /> 3 Bedrooms
//                 </span>
//                 <span>
//                   <BiBath /> 2 Bathrooms
//                 </span>
//                 <span>
//                   <TbLayersIntersect /> 750 Sq.Ft
//                 </span>
//               </div>

//               <div className="card bg-light mt-4">
//                 <div className="row  gy-4">
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Area size</span>
//                     <p className="fw-bolder mb-0">750 sq.ft</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Developer</span>
//                     <p className="fw-bolder mb-0">Jagahdekho Group</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Project</span>
//                     <p className="fw-bolder mb-0">Jagahdekho Tower</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Floor</span>
//                     <p className="fw-bolder mb-0">5(Out of 8 Floors)</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Transaction type</span>
//                     <p className="fw-bolder mb-0">Resale</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Status</span>
//                     <p className="fw-bolder mb-0">Ready to Move</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Furnished Status</span>
//                     <p className="fw-bolder mb-0">Furnished</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Car parking</span>
//                     <p className="fw-bolder mb-0">506 Covered</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Type of Ownership</span>
//                     <p className="fw-bolder mb-0">Co-operative Society</p>
//                   </div>
//                 </div>
//               </div>

//               <button type="button" className="btn btn-primary w-50 d-block mt-4 ">
//                 Contact Dealer
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section style={{ background: "#ffb4001a" }}>
//         <div className="container">
//           <span className="sub-title">Related Properties</span>
//           <h2 className="title mb-4">More Related Properties According to Your Search</h2>
//           <div className="row gy-4">
//             {Array.from({ length: 4 }).map((_, i) => (
//               <div className="col-lg-3 col-6">
//                 <div className="card overflow-visible projects-card">
//                   <div className="theme-img projects-img n-mtx-3">
//                     <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
//                       <i className="bi bi-heart"></i>
//                     </button>
//                     <img src={projects1} alt="projects1" />
//                   </div>

//                   <div className="projects-details position-relative">
//                     <span className="projects-badge">For Sale</span>
//                     <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
//                     <p className="text-muted mb-2">Mulund (West)</p>
//                     <span className="price mb-2 d-block">
//                       $4,000<span className="sub-text">/month</span>
//                     </span>
//                     <div className="d-flex projects-features">
//                       <span>
//                         <LuBedDouble /> 3
//                       </span>
//                       <span>
//                         <BiBath /> 2
//                       </span>
//                       <span>
//                         <TbLayersIntersect /> 750 Sq.Ft
//                       </span>
//                     </div>
//                     <button type="button" className="btn btn-primary w-100 mt-3">
//                       Contact Dealer
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default projectsDetails;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";
import projectsPlaceholder from "../../assets/img/no-photo.webp";

const ProjectsDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { state: projects } = useLocation();
  console.log("projects--", projects);

  const getFormattedPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  const renderRentInfo = () =>
    <div>
  {projects?.sale_type === "Rent" ? (
    <div className="d-flex mb-4 align-items-center">
      <h3 className="text-primary mb-0">
        {getFormattedPrice(projects?.askingPrice)}/month
      </h3>
      <p className="mb-0 ms-2">
        Rate: {getFormattedPrice(projects?.ratePerSqft)} /sq ft
      </p>
    </div>
  ) : (
    <div className="d-flex mb-4 align-items-center">
      <h3 className="text-primary mb-0">
        {getFormattedPrice(projects?.askingPrice)}
      </h3>
      <p className="mb-0 ms-2">
        Rate: {getFormattedPrice(projects?.ratePerSqft)} /sq ft
      </p>
    </div>
  )}
</div>
    
 

  const renderResidentialDetails = () => (
    <>
      <h4>{projects?.projectName}</h4>
      <p className="text-muted">
        {projects?.landmark || "Unknown Landmark"},{" "}
        {projects?.city || "Unknown City"} - {projects?.pincode || "Unknown City"}
      </p>
      {renderRentInfo()}
      <div className="d-flex property-features property-features-v2">
        {projects?.bedroom && (
          <span>
            <LuBedDouble /> {projects?.bedroom} Bedroom
          </span>
        )}
        {projects?.coverArea && (
          <span>
            <TbLayersIntersect /> {projects?.coverArea} Sq.Ft
          </span>
        )}
      </div>

      <div className="fw-bolder pt-3 pb-0">projects Details:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Bedrooms</span>
            <p className="fw-bolder mb-0">{projects?.bedroom} Bds </p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Balconies</span>
            <p className="fw-bolder mb-0">{projects?.balconies}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Total Floor</span>
            <p className="fw-bolder mb-0">{projects?.totalFloor ? projects?.totalFloor : 0 } Floors</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Parkings</span>
            <p className="fw-bolder mb-0">{projects?.parkings}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Lifts</span>
            <p className="fw-bolder mb-0">{projects?.lifts}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Furnished Status</span>
            <p className="fw-bolder mb-0">{projects?.furnished}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Occupancy certificate</span>
            <p className="fw-bolder mb-0">{projects?.occupancyCertificate}</p>
          </div>
        </div>
      </div>
      <div className="fw-bolder pt-3 pb-0">Projects Area:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Cover Area:</span>
            <p className="fw-bolder mb-0">{projects?.coverArea} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Buid-Up Area:</span>
            <p className="fw-bolder mb-0">{projects?.buildupArea} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Carpet Area:</span>
            <p className="fw-bolder mb-0">{projects?.carpetArea ? projects?.carpetArea : 0 }  Sq.Ft</p>
          </div>
        </div>
      </div>

       <div className="fw-bolder pt-3 pb-0">projects Amenities:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{projects?.amenitiesName?.join(', ')}</p>
              </div>
            </div>
          </div>

         <div className="fw-bolder pt-3 pb-0">projects Unique Features:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{projects?.uniqueFeaturesName?.join(', ')}</p>
              </div>
            </div>
          </div>  

          {projects?.description && (
        <>
          <div className="fw-bolder pt-3 pb-0">projects Description:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-4 col-6">
                <p className="text-muted mb-0">{projects.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  const renderCommercialDetails = () => (
    <>
      <h4>{projects?.projectName}</h4>
      <p className="text-muted">
        {projects?.landmark || "Unknown Landmark"},{" "}
        {projects?.city || "Unknown City"} - {projects?.pincode}
      </p>
      {/* {renderRentInfo()} */}
      <div className="d-flex property-features property-features-v2">
        {projects?.coverArea && (
          <span>
            {" "}
            <TbLayersIntersect />
            {projects?.coverArea} Sq.Ft
          </span>
        )}
        
          <span>
            <BiBath />
            {projects?.washroom
              ? "Personal Washroom"
              : "No Personal Washroom"}
          </span>
        
      </div>

      <div className="card bg-light mt-4">
        <div className="row  gy-4">
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Total Floor:</span>
            <p className="fw-bolder mb-0">{projects?.totalFloor} Floors </p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Parkings:</span>
            <p className="fw-bolder mb-0">{projects?.parkings}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Lifts:</span>
            <p className="fw-bolder mb-0">{projects?.lifts}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Pantry:</span>
            <p className="fw-bolder mb-0">{projects?.pantry}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Furnsihed:</span>
            <p className="fw-bolder mb-0">{projects?.furnished}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Personal washroom:</span>
            <p className="fw-bolder mb-0">{projects?.washroom}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Occupancy certificate</span>
            <p className="fw-bolder mb-0">{projects?.occupancyCertificate}</p>
          </div>
        </div>
      </div>

      <div className="fw-bolder pt-3 pb-0">Project Info:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Launch Date:</span>
            <p className="fw-bolder mb-0">{projects?.launchDate}</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Completion Date:</span>
            <p className="fw-bolder mb-0">{projects?.completionDate} </p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Possession Date:</span>
            <p className="fw-bolder mb-0">{projects?.possession} </p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Project Name:</span>
            <p className="fw-bolder mb-0">{projects?.projectName}</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Developer Name:</span>
            <p className="fw-bolder mb-0">{projects?.developerName}</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Rera Number:</span>
            <p className="fw-bolder mb-0">{projects?.reraNumber}</p>
          </div>
        </div>
      </div>

      <div className="fw-bolder pt-3 pb-0">Projects Area:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Cover Area:</span>
            <p className="fw-bolder mb-0">{projects?.coverArea} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Buid-Up Area:</span>
            <p className="fw-bolder mb-0">{projects?.buildupArea} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Carpet Area:</span>
            <p className="fw-bolder mb-0">{projects?.carpetArea ? projects?.carpetArea : 0 }  Sq.Ft</p>
          </div>
        </div>
      </div>

      <div className="fw-bolder pt-3 pb-0">projects Amenities:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{projects?.amenitiesName?.join(', ')}</p>
              </div>
            </div>
          </div>

         <div className="fw-bolder pt-3 pb-0">projects Unique Features:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{projects?.uniqueFeaturesName?.join(', ')}</p>
              </div>
            </div>
          </div>  

          {projects?.description && (
        <>
          <div className="fw-bolder pt-3 pb-0">projects Description:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-4 col-6">
                <p className="text-muted mb-0">{projects.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="main-swiper"
            >
              {(projects?.imagePaths?.length
                ? projects?.imagePaths
                : [null]
              ).map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="theme-img">
                    <img
                      src={image?.path || projectsPlaceholder}
                      alt={`projects Image ${i + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="sub-swiper"
            >
              {(projects?.imagePaths?.length
                ? projects?.imagePaths
                : [null]
              ).map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="theme-img">
                    <img
                      src={image?.path || projectsPlaceholder}
                      alt={`projects Thumbnail ${i + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-lg-7">
            {projects?.propertyType === 1
              ? renderResidentialDetails()
              : renderCommercialDetails()}
            <button type="button" className="btn btn-primary w-50 d-block mt-4">
              Contact Dealer
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsDetails;
