import React, { useState, useEffect } from "react";
import Filter from "./components/Filter";
import Select from "common/components/Select";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";
import propertyPlaceholder from "../../assets/img/no-photo.webp";
import axios from "common/configs/axiosConfigs";
import { useNavigate } from 'react-router-dom';

const Properties = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [webProperties, setWebProperties] = useState([]);
  const [sortLabel, setSortLabel] = useState("New to Old"); 

  const handleNavigate = (property) => {
    navigate(`/property-details/${property.id}`, { state: property }); // Pass the entire property object
  };

  const getWebProperties = async () => {
    try {
      const { data } = await axios.post("", {
        action: "getWebProperties",
        sort: sortLabel, 
      });

      if (data?.success) {
        console.log("dataaaaaaa",data)
        setWebProperties(data?.data);
      } else {
        console.error(data?.error);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getWebProperties();
  }, [sortLabel]);

  const handleSortChange = (selectedOption) => {
    setSortLabel(selectedOption.label); 
    console.log("Selected Sort Label:", selectedOption.label);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <section>
      <div className="container">
        <div className="row align-items-center mb-4">
          <h2>
            Properties{" "}
            <span className="text-muted" style={{ fontSize: 16 }}>
              ({webProperties.length})
            </span>
          </h2>
          <div className="actions-cover d-flex ms-auto">
            <Select
              defaultValue={{ value: "1", label: "New to Old" }}
              options={[
                { value: "1", label: "New to Old" },
                { value: "2", label: "Old to New" },
                { value: "3", label: "High to Low" },
                { value: "4", label: "Low to High" },
              ]}
              onChange={handleSortChange}
            />
            <button
              type="button"
              className="btn btn-dark ms-3"
              onClick={() => setIsOpen(true)}
            >
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="row gy-4">
          {webProperties.length > 0 ? (
            webProperties
              .sort((a, b) => {
                if (sortLabel === "New to Old") return b.entryDate - a.entryDate;
                if (sortLabel === "Old to New") return a.entryDate - b.entryDate;
                if (sortLabel === "High to Low") return b.monthlyRent - a.monthlyRent;
                if (sortLabel === "Low to High") return a.monthlyRent - b.monthlyRent;
                return 0;
              })
              .map((p, i) => (
                <div className="col-lg-3 col-6" key={p?.id}>
                  <div className="card overflow-visible property-card">
                    <div className="theme-img property-img n-mtx-3">
                      {/* <button
                        type="button"
                        className={`wish-list-btn ${i === 2 ? "active" : ""}`}
                      >
                        <i className="bi bi-heart"></i>
                      </button> */}
                      <img
                        src={
                          p?.property_thumbnail
                            ? p.property_thumbnail
                            : propertyPlaceholder
                        }
                        alt="property_thumb"
                      />
                    </div>

                    <div className="property-details position-relative">
                      <span className="property-badge">
                        For {p?.sale_type}
                      </span>
                      <h5>{p?.property_title}</h5>
                      <p className="text-muted mb-2">
                        {p?.landmark}, {p?.city}
                      </p>
                      <span className="price mb-2 d-block">
                        ₹{p?.monthlyRent}
                        <span className="sub-text">/month</span>
                      </span>
                      <div className="d-flex property-features">
                        <span>
                          <LuBedDouble /> {p?.bedroom}
                        </span>
                        <span>
                          <BiBath /> {p?.bathroom}
                        </span>
                        <span>
                          <TbLayersIntersect /> {p?.carept_area} Sq.Ft
                        </span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-3"
                        onClick={() => handleNavigate(p)}
                      >
                        Take a Look
                      </button>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="text-center w-100">
              <p className="text-muted">No properties found.</p>
            </div>
          )}
        </div>
      </div>
      <Filter {...{ onClose, isOpen }} />
    </section>
  );
};

export default Properties;
