import axios from "common/configs/axiosConfigs";
import React, { useEffect, useState } from "react";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const { data } = await axios.post("", {
        action: "getCategoriesCounter",
      });
      if (data?.success) {
        setCategories(data?.data);
      } else {
        console.error(data?.error);
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getCategories();
  }, []);

  return (
    <section className="categories">
      <div className="container">
        <div className="title-cover text-center">
          <span className="sub-title">Categories</span>
          <h2 className="title mx-auto">Search By Property Requirement</h2>
        </div>
        <div className="row gy-4">
          {categories?.map((c) => (
            <div className="col-lg-3 col-6" key={c?.id}>
              <div className="card cardCategories">
                <div className="d-flex justify-content-between align-items-start">
                  <i className="bi bi-building"></i>
                  <button type="button" className="view-all-btn">
                    View All <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
                <h3>{c?.type}</h3>
                <span className="text-muted">{c?.count} properties</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;
