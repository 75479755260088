// import React, { useState } from "react";
// import Filter from "./components/Filter";
// import Select from "common/components/Select";
// import { LuBedDouble } from "react-icons/lu";
// import { BiBath } from "react-icons/bi";
// import { TbLayersIntersect } from "react-icons/tb";
// import property2 from "assets/img/property-2.jpg";
// import { useNavigate } from "react-router-dom";

// const Projects = () => {
//   const navigate = useNavigate();
//   const [isOpen, setIsOpen] = useState(false);
//   const onClose = () => {
//     setIsOpen(false);
//   };
//   return (
//     <section>
//       <div className="container">
//         <div className="row align-items-center mb-4">
//           <h2>
//             Projects{" "}
//             <span className="text-muted" style={{ fontSize: 16 }}>
//               (546)
//             </span>
//           </h2>
//           <div className="actions-cover d-flex ms-auto">
//             <Select
//               defaultValue={{ value: "1", label: "New to Old" }}
//               options={[
//                 { value: "1", label: "New to Old" },
//                 { value: "2", label: "Old to New" },
//               ]}
//             />
//             <button type="button" className="btn btn-dark ms-3" onClick={() => setIsOpen(true)}>
//               <i className="bi bi-funnel"></i>
//             </button>
//           </div>
//         </div>
//         <div className="row gy-4">
//           {Array.from({ length: 8 }).map((_, i) => (
//             <div className="col-lg-3 col-6">
//               <div className="card overflow-visible property-card">
//                 <div className="theme-img property-img n-mtx-3">
//                   <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
//                     <i className="bi bi-heart"></i>
//                   </button>
//                   <img src={property2} alt="property1" />
//                 </div>

//                 <div className="property-details position-relative">
//                   <span className="property-badge">For Sale</span>
//                   <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
//                   <p className="text-muted mb-2">Mulund (West)</p>
//                   <span className="price mb-2 d-block">
//                     $4,000<span className="sub-text">/month</span>
//                   </span>
//                   <div className="d-flex property-features">
//                     <span>
//                       <LuBedDouble /> 3
//                     </span>
//                     <span>
//                       <BiBath /> 2
//                     </span>
//                     <span>
//                       <TbLayersIntersect /> 750 Sq.Ft
//                     </span>
//                   </div>
//                   <button
//                     type="button"
//                     className="btn btn-primary w-100 mt-3"
//                     onClick={() => navigate("/property-details/example-id")}
//                   >
//                     See Details
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//       <Filter {...{ onClose, isOpen }} />
//     </section>
//   );
// };

// export default Projects;

import React, { useState, useEffect } from "react";
import Filter from "./components/Filter";
import Select from "common/components/Select";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";
import propertyPlaceholder from "../../assets/img/no-photo.webp";
import axios from "common/configs/axiosConfigs";
import { useNavigate } from 'react-router-dom';

const Properties = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [projects, setprojects] = useState([]);
  const [sortLabel, setSortLabel] = useState("New to Old"); 
  const [filters, setFilters] = useState({}); 

  const handleNavigate = (projects) => {
    navigate(`/project-details/${projects.id}`, { state: projects }); // Pass the entire property object
  };

  const getprojects = async () => {
    try {
      const { data } = await axios.post("", {
        action: "getWebProjects",
        sort: sortLabel, 
        propertyType: "",
        location: "",
        furnished: "",
        bedroom: "",
        minBudget: "",
        maxBudget: "",
        ...filters,
      });

      if (data?.success) {
        console.log("dataaaaaaa",data)
        setprojects(data?.data);
      } else {
        console.error(data?.error);
      }
    } catch (err) {
      console.error(err);
    }
  };



  useEffect(() => {
    getprojects();
  }, [sortLabel]);

  const projectFilter = async (filter) => {
    try {
      const { data } = await axios.post("", {
        action: "getWebProjects",
        sort: sortLabel, 
        propertyType: "",
        location: "",
        furnished: "",
        bedroom: "",
        minBudget: "",
        maxBudget: "",
        ...filter,
      });

      if (data?.success) {
        console.log("dataaaaaaa",data)
        setprojects(data?.data);
      } else {
        console.error(data?.error);
      }
    } catch (err) {
      console.error(err);
    }
    console.log("filter",filter)
  }

  const handleSortChange = ({target}) => {
    setSortLabel(target.value); 
    // console.log("Selected Sort Label:", selectedOption.label);
  };

  const onApplyFilters = (filterValues) => {
    setFilters(filterValues); // Update the filters state
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <section>
      <div className="container">
        <div className="row align-items-center mb-4">
          <h2>
            Properties{" "}
            <span className="text-muted" style={{ fontSize: 16 }}>
              ({projects?.length})
            </span>
          </h2>
          <div className="actions-cover d-flex ms-auto">
            <Select
              name="sort"
              defaultValue={{ value: "1", label: "New to Old" }}
              options={[
                { value: "New to Old", label: "New to Old" },
                { value: "Old to New", label: "Old to New" },
                { value: "High to Low", label: "High to Low" },
                { value: "Low to High", label: "Low to High" },
              ]}
              onChange={handleSortChange}
            />
            <button
              type="button"
              className="btn btn-dark ms-3"
              onClick={() => setIsOpen(true)}
            >
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="row gy-4">
          {projects?.length > 0 ? (
            projects
              ?.sort((a, b) => {
                if (sortLabel === "New to Old") return b.entryDate - a.entryDate;
                if (sortLabel === "Old to New") return a.entryDate - b.entryDate;
                if (sortLabel === "High to Low") return b.monthlyRent - a.monthlyRent;
                if (sortLabel === "Low to High") return a.monthlyRent - b.monthlyRent;
                return 0;
              })
              ?.map((p, i) => (
                <div className="col-lg-3 col-6" key={p?.id}>
                  <div className="card overflow-visible property-card">
                    <div className="theme-img property-img n-mtx-3">
                      {/* <button
                        type="button"
                        className={`wish-list-btn ${i === 2 ? "active" : ""}`}
                      >
                        <i className="bi bi-heart"></i>
                      </button> */}
                      <img
                        src={
                          p?.thumbnail
                            ? p.thumbnail
                            : propertyPlaceholder
                        }
                        alt="property_thumb"
                      />
                    </div>

                    <div className="property-details position-relative">
                      <span className="property-badge">
                        For Sell
                      </span>
                      <h5>{p?.projectName}</h5>
                      <p className="text-muted mb-2">
                        {p?.landmark}, {p?.city}
                      </p>
                      <span className="price mb-2 d-block">
                        ₹{p?.askingPrice}
                        {/* <span className="sub-text">/month</span> */}
                      </span>
                      <div className="d-flex property-features">
                        <span>
                          <LuBedDouble /> {p?.bedroom ? p?.bedroom : 0  }
                        </span>
                        <span>
                          <BiBath /> {p?.washroom ? p?.washroom : 0}
                        </span>
                        <span>
                          <TbLayersIntersect /> {p?.careptArea ? p?.careptArea : 0} Sq.Ft
                        </span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-3"
                        onClick={() => handleNavigate(p)}
                      >
                        Take a Look
                      </button>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div className="text-center w-100">
              <p className="text-muted">No properties found.</p>
            </div>
          )}
        </div>
      </div>
      <Filter  {...{ onClose: () => setIsOpen(false), isOpen, onApply: onApplyFilters,projectFilter }} />
    </section>
  );
};

export default Properties;
