import React from "react";
import ReactSelect from "react-select";

const Select = ({ options, placeholder = "Select", onChange, value: val, name, isMulti }) => {
  const handleChange = (option) => {
    let { value } = option;

    if (isMulti) value = option.map((o) => o.value);

    onChange?.({ target: { name, value, ...(isMulti && { isMulti: true }) } });
  };
  return (
    <ReactSelect
      {...{
        isMulti,
        options,
        value: isMulti
          ? options?.filter((opt) => val?.includes(opt.value))
          : options?.find((opt) => opt.value == val),
        classNamePrefix: "form-select",
        placeholder,
        onChange: handleChange,
      }}
    />
  );
};

export default Select;