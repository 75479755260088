// import React, { useState } from "react";
// import { useParams } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
// import { FreeMode, Navigation, Thumbs } from "swiper/modules";
// import property1 from "assets/img/property-1.jpeg";
// import { LuBedDouble } from "react-icons/lu";
// import { BiBath } from "react-icons/bi";
// import { TbLayersIntersect } from "react-icons/tb";

// const PropertyDetails = () => {
//   const [thumbsSwiper, setThumbsSwiper] = useState(null);
//   const { id } = useParams();
//   return (
//     <>
//       <section>
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-5">
//               <Swiper
//                 style={{
//                   "--swiper-navigation-color": "#fff",
//                   "--swiper-pagination-color": "#fff",
//                 }}
//                 spaceBetween={10}
//                 navigation={true}
//                 thumbs={{ swiper: thumbsSwiper }}
//                 modules={[FreeMode, Navigation, Thumbs]}
//                 className="main-swiper"
//               >
//                 {Array.from({ length: 8 }).map((_, i) => (
//                   <SwiperSlide>
//                     <div className="theme-img">
//                       <img src={property1} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//               <Swiper
//                 onSwiper={setThumbsSwiper}
//                 freeMode={true}
//                 watchSlidesProgress={true}
//                 modules={[FreeMode, Navigation, Thumbs]}
//                 className="sub-swiper"
//               >
//                 {Array.from({ length: 8 }).map((_, i) => (
//                   <SwiperSlide>
//                     <div className="theme-img">
//                       <img src={property1} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </div>
//             <div className="col-lg-7">
//               <h4>960 Sq.Ft. 3 BHK Residential Apartment for Sale in Mulund (West)</h4>
//               <p className="text-muted">Mulund (West) , Mumbai Central Suburbs</p>
//               <div className="d-flex mb-4 align-items-center">
//                 <h3 className="text-primary mb-0">$1,50,000</h3>
//                 <p className="mb-0 ms-2">EMI: $5000/month</p>
//               </div>
//               <div className="d-flex  property-features property-features-v2">
//                 <span>
//                   <LuBedDouble /> 3 Bedrooms
//                 </span>
//                 <span>
//                   <BiBath /> 2 Bathrooms
//                 </span>
//                 <span>
//                   <TbLayersIntersect /> 750 Sq.Ft
//                 </span>
//               </div>

//               <div className="card bg-light mt-4">
//                 <div className="row  gy-4">
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Area size</span>
//                     <p className="fw-bolder mb-0">750 sq.ft</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Developer</span>
//                     <p className="fw-bolder mb-0">Jagahdekho Group</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Project</span>
//                     <p className="fw-bolder mb-0">Jagahdekho Tower</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Floor</span>
//                     <p className="fw-bolder mb-0">5(Out of 8 Floors)</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Transaction type</span>
//                     <p className="fw-bolder mb-0">Resale</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Status</span>
//                     <p className="fw-bolder mb-0">Ready to Move</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Furnished Status</span>
//                     <p className="fw-bolder mb-0">Furnished</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Car parking</span>
//                     <p className="fw-bolder mb-0">506 Covered</p>
//                   </div>
//                   <div className="col-lg-3 col-md-4 col-6">
//                     <span className="text-muted">Type of Ownership</span>
//                     <p className="fw-bolder mb-0">Co-operative Society</p>
//                   </div>
//                 </div>
//               </div>

//               <button type="button" className="btn btn-primary w-50 d-block mt-4 ">
//                 Contact Dealer
//               </button>
//             </div>
//           </div>
//         </div>
//       </section>
//       <section style={{ background: "#ffb4001a" }}>
//         <div className="container">
//           <span className="sub-title">Related Properties</span>
//           <h2 className="title mb-4">More Related Properties According to Your Search</h2>
//           <div className="row gy-4">
//             {Array.from({ length: 4 }).map((_, i) => (
//               <div className="col-lg-3 col-6">
//                 <div className="card overflow-visible property-card">
//                   <div className="theme-img property-img n-mtx-3">
//                     <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
//                       <i className="bi bi-heart"></i>
//                     </button>
//                     <img src={property1} alt="property1" />
//                   </div>

//                   <div className="property-details position-relative">
//                     <span className="property-badge">For Sale</span>
//                     <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
//                     <p className="text-muted mb-2">Mulund (West)</p>
//                     <span className="price mb-2 d-block">
//                       $4,000<span className="sub-text">/month</span>
//                     </span>
//                     <div className="d-flex property-features">
//                       <span>
//                         <LuBedDouble /> 3
//                       </span>
//                       <span>
//                         <BiBath /> 2
//                       </span>
//                       <span>
//                         <TbLayersIntersect /> 750 Sq.Ft
//                       </span>
//                     </div>
//                     <button type="button" className="btn btn-primary w-100 mt-3">
//                       Contact Dealer
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default PropertyDetails;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";
import propertyPlaceholder from "../../assets/img/no-photo.webp";

const PropertyDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { state: property } = useLocation();
  console.log("property--", property);

  const getFormattedPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  const renderRentInfo = () =>
    <div>
  {property?.sale_type === "Rent" ? (
    <div className="d-flex mb-4 align-items-center">
      <h3 className="text-primary mb-0">
        {getFormattedPrice(property?.monthlyRent)}/month
      </h3>
      <p className="mb-0 ms-2">
        Rate: {getFormattedPrice(property?.rate)} /sq ft
      </p>
    </div>
  ) : (
    <div className="d-flex mb-4 align-items-center">
      <h3 className="text-primary mb-0">
        {getFormattedPrice(property?.monthlyRent)}
      </h3>
      <p className="mb-0 ms-2">
        Rate: {getFormattedPrice(property?.rate)} /sq ft
      </p>
    </div>
  )}
</div>
    
 

  const renderResidentialDetails = () => (
    <>
      <h4>{property?.property_title}</h4>
      <p className="text-muted">
        {property?.landmark || "Unknown Landmark"},{" "}
        {property?.city || "Unknown City"} - {property?.pincode || "Unknown City"}
      </p>
      {renderRentInfo()}
      <div className="d-flex property-features property-features-v2">
        {property?.bedroom && (
          <span>
            <LuBedDouble /> {property?.bedroom} Bedroom
          </span>
        )}
        {property?.bathroom && (
          <span>
            <BiBath /> {property?.bathroom} Bathroom
          </span>
        )}
        {property?.cover_area && (
          <span>
            <TbLayersIntersect /> {property?.cover_area} Sq.Ft
          </span>
        )}
      </div>

      <div className="fw-bolder pt-3 pb-0">Property Details:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Bedrooms</span>
            <p className="fw-bolder mb-0">{property?.bedroom} Bds </p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Balconies</span>
            <p className="fw-bolder mb-0">{property?.balconies}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Floor</span>
            <p className="fw-bolder mb-0">{property?.floor_no} Floor (out of {property?.total_floor} Floor)</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Parkings</span>
            <p className="fw-bolder mb-0">{property?.parkings}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Lifts</span>
            <p className="fw-bolder mb-0">{property?.lifts}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Bathroom</span>
            <p className="fw-bolder mb-0">{property?.bathroom}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Furnished Status</span>
            <p className="fw-bolder mb-0">{property?.furnished}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Property age</span>
            <p className="fw-bolder mb-0">{property?.property_age} Years</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Occupancy certificate</span>
            <p className="fw-bolder mb-0">{property?.occupancy_certificate}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Enterance direction</span>
            <p className="fw-bolder mb-0">{property?.entrance_direction}</p>
          </div>
        </div>
      </div>
      <div className="fw-bolder pt-3 pb-0">Property Area:</div>
      <div className="card bg-light mt-2 ">
        <div className="row  gy-4">
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Cover Area:</span>
            <p className="fw-bolder mb-0">{property?.cover_area} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Buid-Up Area:</span>
            <p className="fw-bolder mb-0">{property?.buildup_area} Sq.Ft</p>
          </div>
          <div className="col-lg-4 col-md-4 col-6">
            <span className="text-muted">Carpet Area:</span>
            <p className="fw-bolder mb-0">{property?.carept_area} Sq.Ft</p>
          </div>
        </div>
      </div>

       <div className="fw-bolder pt-3 pb-0">Property Amenities:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{property?.amenities_names?.join(', ')}</p>
              </div>
            </div>
          </div>

         <div className="fw-bolder pt-3 pb-0">Property Unique Features:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-12 col-md-12 col-12">
                <p className="text-muted mb-0">{property?.unique_features_name?.join(', ')}</p>
              </div>
            </div>
          </div>  

          {property?.description && (
        <>
          <div className="fw-bolder pt-3 pb-0">Property Description:</div>
          <div className="card bg-light mt-2">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-4 col-6">
                <p className="text-muted mb-0">{property.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  const renderCommercialDetails = () => (
    <>
      <h4>{property?.property_title}</h4>
      <p className="text-muted">
        {property?.landmark || "Unknown Landmark"},{" "}
        {property?.city || "Unknown City"}
      </p>
      {renderRentInfo()}
      <div className="d-flex property-features property-features-v2">
        {property?.cover_area && (
          <span>
            {" "}
            <TbLayersIntersect />
            {property?.cover_area} Sq.Ft
          </span>
        )}
        {property?.personal_washroom && (
          <span>
            <BiBath />
            {property?.personal_washroom
              ? "Personal Washroom"
              : "No Personal Washroom"}
          </span>
        )}
      </div>

      <div className="card bg-light mt-4">
        <div className="row  gy-4">
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Floor</span>
            <p className="fw-bolder mb-0">{property?.floor_no} </p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Parkings</span>
            <p className="fw-bolder mb-0">{property?.parkings}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Lifts</span>
            <p className="fw-bolder mb-0">{property?.lifts}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Pantry</span>
            <p className="fw-bolder mb-0">{property?.pantry}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">furnsihed</span>
            <p className="fw-bolder mb-0">{property?.furnished}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Property age</span>
            <p className="fw-bolder mb-0">{property?.property_age}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Enterance direction</span>
            <p className="fw-bolder mb-0">{property?.entrance_direction}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Common washroom</span>
            <p className="fw-bolder mb-0">{property?.bathroom}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Personal washroom</span>
            <p className="fw-bolder mb-0">{property?.personal_washroom}</p>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <span className="text-muted">Occupancy certificate</span>
            <p className="fw-bolder mb-0">{property?.occupancy_certificate}</p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="main-swiper"
            >
              {(property?.image_paths?.length
                ? property?.image_paths
                : [null]
              ).map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="theme-img">
                    <img
                      src={image?.path || propertyPlaceholder}
                      alt={`Property Image ${i + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="sub-swiper"
            >
              {(property?.image_paths?.length
                ? property?.image_paths
                : [null]
              ).map((image, i) => (
                <SwiperSlide key={i}>
                  <div className="theme-img">
                    <img
                      src={image?.path || propertyPlaceholder}
                      alt={`Property Thumbnail ${i + 1}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-lg-7">
            {property?.property_type === 1
              ? renderResidentialDetails()
              : renderCommercialDetails()}
            <button type="button" className="btn btn-primary w-50 d-block mt-4">
              Contact Dealer
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyDetails;
