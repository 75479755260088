import React from "react";

const Privacy = () => {
  return (
    <section className="container">
      <h3>Privacy Policy For Jagah Dekho Application</h3>
      <p>
        This privacy policy applies to the Capital Services app (hereinafter referred to as the
        "Application") for mobile devices, created by Capital Services (hereinafter referred to as
        the "Service Provider") as an Open Source service. This service is intended for use "AS IS".
      </p>

      <h3>Information Collection and Use</h3>
      <p>
        The Application collects information when you download and use it. This information may
        include: The pages of the Application that you visit, the time and date of your visit, the
        time spent on those pages The operating system you use on your mobile device Information you
        provide when using our loan tracking application, such as login credentials (email and
        password).
      </p>
      <h3>Use of Information</h3>

      <p>
        Analytics and Improvements: Aggregated and anonymized data helps the Service Provider to
        analyze user behavior, identify trends, and improve the overall performance and
        functionality of the Application.
      </p>

      <p>
        Third-Party Services: Periodically, the Service Provider may transmit anonymized location
        data to external services. These services assist them in enhancing the Application and
        optimizing their offerings.
      </p>

      <p>
        The Service Provider may use the information you provided to contact you from time to time
        to provide you with important information, required notices, and marketing promotions.
      </p>

      <p>
        For a better experience while using the Application, the Service Provider may require you to
        provide us with certain personally identifiable information, including but not limited to
        Email, Contact No., and Permission only from staff members. The information that the Service
        Provider requests will be retained by them and used as described in this privacy policy.
      </p>

      <h3>Third Party Access</h3>
      <p>
        Only aggregated, anonymized data is periodically transmitted to external services to aid the
        Service Provider in improving the Application and their service. The Service Provider may
        share your information with third parties in the ways that are described in this privacy
        statement.
      </p>

      <p>
        Please note that the Application utilizes third-party services that have their own Privacy
        Policy about handling data. Below are the links to the Privacy Policy of the third-party
        service providers used by the Application:
      </p>

      <h3>Google Play Services</h3>
      <p>
        The Service Provider may disclose User Provided and Automatically Collected Information:
      </p>

      <p>As required by law, such as to comply with a subpoena, or similar legal process;</p>

      <p>
        When they believe in good faith that disclosure is necessary to protect their rights,
        protect your safety or the safety of others, investigate fraud, or respond to a government
        request;
      </p>

      <p>
        With their trusted service providers who work on their behalf, do not have an independent
        use of the information we disclose to them, and have agreed to adhere to the rules set forth
        in this privacy statement.
      </p>

      <h3>Opt-Out Rights</h3>
      <p>
        You can stop all collection of information by the Application easily by uninstalling it. You
        may use the standard uninstall processes as may be available as part of your mobile device
        or via the mobile application marketplace or network.
      </p>

      <h3>Data Retention Policy</h3>
      <p>
        The Service Provider will retain User Provided data for as long as you use the Application
        and for a reasonable time thereafter. If you'd like them to delete User Provided Data that
        you have provided via the Application, please contact them at
        capitalservicessurat@gmail.com, and they will respond in a reasonable time.
      </p>

      <h3>Children</h3>
      <p>
        The Service Provider does not use the Application to knowingly solicit data from or market
        to children under the age of 13.
      </p>

      <p>
        The Application does not address anyone under the age of 13. The Service Provider does not
        knowingly collect personally identifiable information from children under 13 years of age.
        In the case the Service Provider discovers that a child under 13 has provided personal
        information, the Service Provider will immediately delete this from their servers. If you
        are a parent or guardian and you are aware that your child has provided us with personal
        information, please contact the Service Provider (capitalservicessurat@gmail.com) so that
        they will be able to take the necessary actions.
      </p>

      <h3>Security</h3>
      <p>
        The Service Provider is concerned about safeguarding the confidentiality of your
        information. The Service Provider provides physical, electronic, and procedural safeguards
        to protect information the Service Provider processes and maintains.
      </p>

      <h3>Changes</h3>
      <p>
        This Privacy Policy may be updated from time to time for any reason. The Service Provider
        will notify you of any changes to the Privacy Policy by updating this page with the new
        Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as
        continued use is deemed approval of all changes.
      </p>

      <p>This privacy policy is effective as of 2024-03-21.</p>

      <h3>Your Consent</h3>
      <p>
        By using the Application, you are consenting to the processing of your information as set
        forth in this Privacy Policy now and as amended by us.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions regarding privacy while using the Application, or have questions
        about the practices, please contact the Service Provider via email at
        capitalservicessurat@gmail.com.
      </p>

      <p>
        Please ensure to replace "Service Provider" and "Application" with your actual
        company/developer name and app name, respectively. Additionally, make sure to update the
        email address and effective date as necessary.
      </p>
    </section>
  );
};

export default Privacy;
